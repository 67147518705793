@import 'assets/scss/keepspace-css/css-reset';
@import 'assets/scss/keepspace-css/loading-spinner';

$color-success: #00c851;
$color-error: #ff4444;
$stroke-width: 5px;
$size: 150px;
$checkmark-color: white;

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: #f4f6f8;
	//font-size: 14px;

	// iPhone X 'Notch' landscape fix
	padding: constant(safe-area-inset-top) constant(safe-area-inset-right)
		constant(safe-area-inset-bottom) constant(safe-area-inset-left);
}

appRoot {
	background-color: '#f4f6f8';
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	text-decoration: none;
	color: inherit;
}

.slaask-mini {
	right: unset;
	left: 20px;
}

.MuiPickersDay-root.Mui-selected {
	background-color: #43a047 !important;
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlight {
	color: #fff !important;
	border-radius: 0 !important;
	background-color: rgba(104, 179, 107, 0.6) !important;
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd {
	margin-right: 1px;
	padding-right: 0;
	border-top-right-radius: 50% !important;
	border-bottom-right-radius: 50% !important;
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlight:first-child {
	border-top-left-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
}

.MuiPickersDateRangeDay-rangeIntervalDayHighlightStart {
	margin-left: 1px;
	padding-left: 0;
	border-top-left-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
}

/*
.tire-field-wrap .MuiTextField-root::before{
  content: '%'
}*/

.MuiDrawer-paperAnchorLeft {
	width: 256px;
}

.MuiDrawer-paperAnchorLeft {
	width: 256px;
}

.MuiPickersDay-daySelected {
	background-color: #40b5ad;
}

svg.tickout {
	width: 300px;
	height: 260px;
}

.tick {
	transform-origin: center;
	animation: grow 0.8s ease-in-out forwards;
}

@keyframes grow {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	60% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.circle {
	transform-origin: center;
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
	animation: dash 1s linear;
}

@keyframes dash {
	from {
		stroke-dashoffset: 1000;
		opacity: 0;
	}
	to {
		stroke-dashoffset: 0;
	}
}

.checkmark_circle_error {
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: $stroke-width;
	stroke-miterlimit: 10;
	stroke: $color-error;
	animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
	width: $size;
	height: $size;
	border-radius: 50%;
	display: block;
	stroke-width: $stroke-width;
	stroke: $checkmark-color;
	stroke-miterlimit: 10;
	margin: 10% auto;
	&.error {
		box-shadow: inset 0px 0px 0px $color-error;
		animation: fillerror 0.4s ease-in-out 0.4s forwards,
			scale 0.3s ease-in-out 0.9s both;
	}
}

.checkmark_check {
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards;
}

.progress {
	transform: rotate(-90deg);
	stroke: black;
}

.progress circle {
	stroke-dasharray: 130;
	stroke-dashoffset: 130;
	animation: dash 1.5s infinite;
}

@keyframes dash {
	50% {
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dashoffset: -130;
	}
}

.progress {
	position: absolute;
	top: 5%;
	left: 5%;

	&.progress--thin {
		left: auto;
		right: 5%;
	}
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes scale {
	0%,
	100% {
		transform: none;
	}
	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fillerror {
	100% {
		box-shadow: inset 0px 0px 0px $size/2 $color-error;
	}
}
