.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  //padding: 6px!important;
}

.MuiTableCell-head {
  //padding:14px 0 !important;
  text-align: center;
}

.MuiPaginationItem-root {
  margin: 0 1px !important;
  padding: 0 2px !important;
}

sup {
  font-size: xx-small !important;
  vertical-align: super !important;
}

.MuiInputLabel-outlined {
  background-color: #fff;
}

body .MuiTypography-h3,
body .MuiTypography-h2 {
  letter-spacing: 0.06em !important;
  font-weight: 900 !important;
}

input[type="date"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

/* 
body *::-webkit-scrollbar {
    width: .8em 
  }
body *::-webkit-scrollbar-track {
  background-color: #0000000A;
  border-radius: 10px;
}
body *::-webkit-scrollbar-thumb {
  background-color: #3FB5AC;
  border-radius: 10px;
}*/

.image___xtQGH {
  background-size: 100%, 100% !important;
  background-repeat: no-repeat !important;
}

.MuiButton-root:hover {
  color: inherit !important;
  opacity: 0.8 !important;
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

.MuiTabs-scrollButtonsDesktop {
  display: inline-flex !important;
  color: #40b5ad !important;
}