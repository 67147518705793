@import '_variables.scss';
@import '_overrides.scss';

//https://fonts.google.com/icons?selected=Material%20Icons%20Outlined%3Asupport_agent%3A
* {
  box-sizing: border-box;
  line-height: 1.3em !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  height: 100vh;
  min-height: 100%;
  background-color: $mainBgColor;
  font-size: 14px;

  & * {
    letter-spacing: 0.08em !important;
  }

}

.appRoot,
.App {
  background-color: $mainBgColor;
  //height: 100vh;
}

.topBar {
  z-index: 10;
  position: fixed;
  width: 100vw
}

.container {
  display: flex;
  flex: 1 1 auto;
  //overflow: scroll;
  height: 100%;
  margin-top: 44px
}

@media screen and (min-width: 600px) {
  .container {
    margin-top: 52px;
  }
}

.navBar {
  z-index: 3;
  width: 15vw;
  min-width: 256px;
  flex: 0 0 auto
}

.main {
  width: 82vw;
  margin: 0 auto;
}

@media screen and (max-width: 960px) {
  .main {
    width: 100vw
  }
}

/*------------------Menu----------------------*/

.menuBar {
  padding: 16px 0;
  overflow: auto;
  height: 92vh;

  & .name {
    padding: 22px 30px 15px;
  }

  & .divider {
    background-color: $darkDivider;
  }

  & .navigation {
    padding: 20px;
    height: 90%;
    overflow: auto;
  }

  & .navMenuItem {
    & a {
      padding: 17px 5px 17px
    }

    & button {
      padding: 17px 5px 17px
    }

  }

  & .version {
    text-align: center;

  }
}

/*-------------Main container------------------------*/
.root {
  width: 100%;
  flex-wrap: wrap;
  padding: 35px;
}

@media screen and (max-width: 960px) {
  .root {
    padding: 25px 24px;
  }

  body {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .root {
    padding: 20px;
  }

  body {
    font-size: 13px;
  }
}


.themeTextColor {
  color: $themeColor;
}

/*-------------loading spinner------------------------*/
.loaderContent {
  margin-top: 24px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 3;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.spinner {
  margin: 45vh auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1,
.dot2,
.dot3 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #5EDFFF;
  border-radius: 100%;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
  background-color: #1089FE
}

.dot3 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -2.0s;
  animation-delay: -2.0s;
  background-color: #00E0E0
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg)
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg)
  }
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0.0)
  }

  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}


/**
 * ==============================================
 * Dot Windmill
 * ==============================================
 */

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
  margin: 45vh auto;
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
}


.dot-windmill {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #5EDFFF;
  color: #5EDFFF;
  transform-origin: 5px 15px;
  -webkit-animation: dot-windmill 2s infinite linear;
  animation: dot-windmill 2s infinite linear;
}

.dot-windmill::before,
.dot-windmill::after {
  content: '';
  display: inline-block;
  position: absolute;
}

.dot-windmill::before {
  left: -8.66px;
  top: 80px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1089FE;
  color: #1089FE;
}

.dot-windmill::after {
  left: 8.66px;
  top: 80px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00E0E0;
  color: #00E0E0;
}

@-webkit-keyframes dot-windmill {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}

@keyframes dot-windmill {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}


/*----------------Responsive Layout------------------*/


@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
      @each $property in $properties {
        #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
    }

    @media screen and (min-width: $max-vw) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

* {
  box-sizing: border-box;
  //font-size: 1.4rem;
  //line-height: 1.6rem;
}


html {
  box-sizing: border-box;
  height: 100%;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}


@media (min-width: 1280px) {
  body {
    font-size: 16px;
  }
}

@media (min-width: 960px) {
  body {
    font-size: 14px;
  }
}

@media (min-width: 600px) {
  body {
    font-size: 12px;
  }
}